<div [ngClass]='rtlLocale'>
    <div class="container tt-fixed">
        <nav role="navigation" id="top" class="tt-main-navigation-module">
            <div class="tt-navigation-container">
                <div *ngIf="localeDropDownHideShow" id="langSelectDiv" class="dropdown">
                    <form [formGroup]="localeForm">
                        <input type="button" (blur)="closeDropDown()" (click)="openDropDown()"
                            [value]="localeForm.value.localeName"
                            class="btn dropdown-toggle btn-default locale-drop-down"
                            [ngClass]="{'locale-drop-down-ssangyong':oemName==='ssangyong'}">
                        <span class="caret"></span>
                    </form>
                    <div *ngIf="localeDropDownToggle" class="open display-style">
                        <ul class="dropdown-menu inner locale-drop-down-option"
                            [ngClass]="{'locale-drop-down-ssangyong':oemName==='ssangyong'}">
                            <li *ngFor="let locale of locales"
                                [ngClass]="{'selected': localeForm.value.localeCode === locale.localeCode}">
                                <a (mousedown)="updateLocale(locale.localeCode,locale.name)">{{locale.name}}</a>
                            </li>
                        </ul>
                    </div>
                </div>


                <div class="tt-gradient-borderindex">
                    <ul class="tt-nav tt-nav-left ullist-style" type="none"
                        [ngClass]="{'tt-nav':oemName!=='opel','headingStyle-opel':oemName==='opel','headingStyle-ssangyong':oemName==='ssangyong'}">
                        <li>
                            <a [routerLink]="['/downloads']" [class.border-link]="brand !== ''"
                                routerLinkActive="border-link" [routerLinkActiveOptions]="{exact: true}" id="homePage"
                                [innerHTML]="'index.Home' |
                                translate" [ngClass]="{'border-link-ssangyong':oemName==='ssangyong'}">
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['/downloads/help']" id="helpPage" [queryParams]="{id:subscribedData }"
                                routerLinkActive="border-link" [innerHTML]="'index.Help' |
                                translate" [ngClass]="{'border-link-ssangyong':oemName==='m'}">
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </div>

    <div class="tt-wrapper">
        <div class="tt-overlay"></div>
        <div class="tt-container tt-banner-module">
            <div id="bannerCarousel" class="carousel slide" data-ride="carousel">
                <ol class="carousel-indicators hidden-md hidden-lg">
                    <li data-target="#bannerCarousel" data-slide-to="0" class="active"></li>
                </ol>
                <div class="item active">

                    <div class="tt-banner-container">
                        <div class="container tt-banner-image-container">
                            <div class="tt-centered-img">
                                <div>
                                    <span class="tt-responsive-image" data-always-load="true"
                                        data-alt='Image Not Available'>
                                        <img src="/assets/images/{{oemName}}/{{getBannerNameBasedOnOem(oemName)}}"
                                            alt="Image Not Available" class="banner-img" usemap="#planetmap1.jpg">
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>