import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-iveco-help-section',
  templateUrl: './iveco-help-section.component.html',
  styleUrls: ['./iveco-help-section.component.less']
})
export class IvecoHelpSectionComponent implements OnInit {

  @Input() subscribedData1: any;

  constructor() { }

  ngOnInit(): void {
  }

}
