import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-iveco-download-popup',
  templateUrl: './iveco-download-popup.component.html',
  styleUrls: ['./iveco-download-popup.component.less']
})
export class IvecoDownloadPopupComponent implements OnInit {

  @Input() downloadUrlData: any;
  @Input() popupDisplayData: any;
  modalToggle = false;
  downloadMapUrls = [];
  downloadLicenseUrls = [];
  @Output() closePopup = new EventEmitter<boolean>();
  oemName!: string;

  constructor(
    private dataService: DataService
  ) { }

  ngOnInit(): void {
    this.oemName = this.dataService.getOemName();
    for (const x of this.downloadUrlData) {
      this.downloadMapUrls = x.URL_DESC.split(';');
    }
    this.openModal();
  }

  openModal() {
    this.modalToggle = true;
  }

  cancel() {
    this.modalToggle = false;
    this.closePopup.emit(this.modalToggle);
  }

  ngOnDestroy(): void {
    this.downloadMapUrls = [];
    this.downloadLicenseUrls = [];
  }

  addUndeScoreInsteadOfBlankSpace(regionName: string) {
    return regionName.split(" ").join("_");
  }

}
