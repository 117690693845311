import { Component, HostListener, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-iveco-drop-down',
  templateUrl: './iveco-drop-down.component.html',
  styleUrls: ['./iveco-drop-down.component.less']
})
export class IvecoDropDownComponent implements OnInit {

  @Input() vehicleInfo: {
    ID: number,
    NAME: string,
    OEM_ID: number
  }[] = [];
  dropDownForm!: FormGroup;
  firstDorpDownValue: {
    ID: number,
    NAME: string,
    DESCRIPTION: string,
    PARENT_ID: number,
    VEHICLE_MODEL_TYPE_ID: number
  }[] = [];
  secondDropDownValue: {
    ID: number,
    NAME: string,
    DESCRIPTION: string,
    PARENT_ID: number,
    VEHICLE_MODEL_TYPE_ID: number
  }[] = [];
  thirdDropDownValue: {
    ID: number,
    NAME: string,
    DESCRIPTION: string,
    PARENT_ID: number,
    VEHICLE_MODEL_TYPE_ID: number
  }[] = [];
  package: [] = [];
  packageInfo: {
    ID: number,
    NAME: string,
    DESCRIPTION: string,
    SIZE: string,
    VERSION: string,
    IMAGE_NAME: string,
    UPLOAD_DATE: string
  }[] = [];
  submitted = false;

  scrollDown: number = 99;

  brandDropDownToggle: boolean = false;

  constructor(
    private fb: FormBuilder,
    private dataService: DataService,
    private translate: TranslateService
  ) {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.dropDownForm.setValue({
        firstDropDown: null,
        firstDropDownVal: null
      });
    });
  }

  ngOnInit(): void {
    this.dropDownForm = this.fb.group({
      firstDropDown: [null, [Validators.required]],
      firstDropDownVal: [null, [Validators.required]]
    });
    for (const val of this.vehicleInfo) {
      this.firstDorpDownValue = this.dataService.getFirstDropDownValue(val.ID);
      if (this.firstDorpDownValue.length > 0) {
        break;
      }
    }
  }

  get f(): { [key: string]: AbstractControl } {
    return this.dropDownForm.controls;
  }

  openBrandDropDown() {
    this.brandDropDownToggle = !this.brandDropDownToggle;
  }


  closeDropDown() {
    this.brandDropDownToggle = false;
  }

  fetchCarModelData(id: number | null, name: string | null) {
    this.dropDownForm.patchValue({
      firstDropDown: id,
      firstDropDownVal: name
    });
    if (this.dropDownForm.value.firstDropDown) {
      this.secondDropDownValue = this.dataService.getOtherDropDownValues(Number(this.dropDownForm.value.firstDropDown));
    }
    this.brandDropDownToggle = false;
  }

  fetchPackageData() {
    this.submitted = true;
    if (this.dropDownForm.invalid) {
      return;
    }
    this.package = [];
    // this.downloadUrlData = [];
    const packageId: {
      PACKAGE_ID: number,
      VEHICLE_MODEL_ID: number
    }[] = this.dataService.getPackageId(this.dropDownForm.value.firstDropDown);
    let result: any = [];
    for (const val of packageId) {
      result.push(this.dataService.getPackageData(val.PACKAGE_ID));
    }
    this.package = result;
    this.submitted = false;
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.scrollDown = window.scrollY;
  }

}
