import { Component, Input, OnInit, HostListener, AfterViewInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/services/data.service';


@Component({
  selector: 'app-fca-drop-down',
  templateUrl: './fca-drop-down.component.html',
  styleUrls: ['./fca-drop-down.component.less']
})
export class FcaDropDownComponent implements OnInit, AfterViewInit {

  @Input() vehicleInfo: {
    ID: number,
    NAME: string,
    OEM_ID: number
  }[] = [];
  dropDownForm!: FormGroup;
  firstDorpDownValue: {
    ID: number,
    NAME: string,
    DESCRIPTION: string,
    PARENT_ID: number,
    VEHICLE_MODEL_TYPE_ID: number
  }[] = [];
  secondDropDownValue: {
    ID: number,
    NAME: string,
    DESCRIPTION: string,
    PARENT_ID: number,
    VEHICLE_MODEL_TYPE_ID: number
  }[] = [];
  thirdDropDownValue: {
    ID: number,
    NAME: string,
    DESCRIPTION: string,
    PARENT_ID: number,
    VEHICLE_MODEL_TYPE_ID: number
  }[] = [];
  package: [] = [];
  packageInfo: {
    ID: number,
    NAME: string,
    DESCRIPTION: string,
    SIZE: string,
    VERSION: string,
    IMAGE_NAME: string,
    UPLOAD_DATE: string
  }[] = [];
  submitted = false;

  scrollDown: number = 99;

  brandDropDownToggle: boolean = false;
  modelDropDownToggle: boolean = false;
  naviDropDownToggle: boolean = false;

  constructor(
    private fb: FormBuilder,
    private dataService: DataService,
    private route: ActivatedRoute,
    private translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.dropDownForm = this.fb.group({
      firstDropDown: [null, [Validators.required]],
      firstDropDownVal: [null, [Validators.required]],
      secondDropDown: [null, [Validators.required]],
      secondDropDownVal: [null, [Validators.required]],
      thirdDropDown: [null, [Validators.required]],
      thirdDropDownVal: [null, [Validators.required]],
    });
    for (const val of this.vehicleInfo) {
      this.firstDorpDownValue = this.dataService.getFirstDropDownValue(val.ID);
      if (this.firstDorpDownValue.length > 0) {
        this.route.queryParams
          .subscribe(params => {
            if (params && params['brand']) {
              const found = this.firstDorpDownValue.filter((data: any) => {
                return (data.NAME).replace(' ', '').toLowerCase() === params['brand'];
              });
              if (found && found.length > 0) {
                this.dropDownForm.patchValue({
                  firstDropDown: found[0].ID,
                  firstDropDownVal: found[0].NAME
                });
                if (this.dropDownForm.value.firstDropDown) {
                  this.secondDropDownValue = this.dataService.getOtherDropDownValues(Number(this.dropDownForm.value.firstDropDown));
                }
                this.brandDropDownToggle = false;
              }
            } else {
              this.dropDownForm.patchValue({
                firstDropDown: null,
                firstDropDownVal: null,
                secondDropDown: null,
                secondDropDownVal: null,
                thirdDropDown: null,
                thirdDropDownVal: null
              });
            }
          }
          );
        break;
      }
    }
  }

  ngAfterViewInit() {
    this.changeValueIntoInnerHtml('index.firstDropDown', 1);
    this.changeValueIntoInnerHtml('index.secondDropDown', 2);
    this.changeValueIntoInnerHtml('index.thirdDropDown', 3);
  }

  changeValueIntoInnerHtml(key: string, numberInput: number) {
    const input: any = document.querySelectorAll("input")[numberInput];
    if (input) {
      this.translate.get(key).subscribe((result: any) => {
        input.textContent  = result;
        setTimeout(() => {
          if (this.dropDownForm.value.firstDropDown) {
            this.dropDownForm.patchValue({
              firstDropDown: this.dropDownForm.value.firstDropDown,
              firstDropDownVal: this.dropDownForm.value.firstDropDownVal,
            });
          } else {
            input.value = input.textContent;
          }
        }, 500);
      });
      this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
        input.textContent = this.translate.instant(key);
        setTimeout(() => {
          // this.dropDownForm.reset();
          this.route.queryParams
            .subscribe(params => {
              if (params && params['brand']) {
                const found = this.firstDorpDownValue.filter((data: any) => {
                  return (data.NAME).replace(' ', '').toLowerCase() === params['brand'];
                });
                if (found && found.length > 0) {
                  this.dropDownForm.patchValue({
                    firstDropDown: found[0].ID,
                    firstDropDownVal: found[0].NAME,
                    secondDropDown: null,
                    secondDropDownVal: null,
                    thirdDropDown: null,
                    thirdDropDownVal: null
                  });
                  if (this.dropDownForm.value.firstDropDown) {
                    this.secondDropDownValue = this.dataService.getOtherDropDownValues(Number(this.dropDownForm.value.firstDropDown));
                  }
                  this.brandDropDownToggle = false;
                }
              } else {
                this.dropDownForm.patchValue({
                  firstDropDown: null,
                  firstDropDownVal: null,
                  secondDropDown: null,
                  secondDropDownVal: null,
                  thirdDropDown: null,
                  thirdDropDownVal: null
                });
                input.value = input.textContent;
              }
            });
        }, 500);
      });
    }
  }

  get f(): { [key: string]: AbstractControl } {
    return this.dropDownForm.controls;
  }

  openBrandDropDown() {
    this.brandDropDownToggle = !this.brandDropDownToggle;
    this.dropDownForm.controls['secondDropDown'].valueChanges.subscribe(value => {
      if (value !== this.dropDownForm.value['secondDropDown']) {
        this.thirdDropDownValue = [];
        this.dropDownForm.patchValue({
          thirdDropDown: null,
          thirdDropDownVal: null,
        });
      }
    });
  }

  openModelDropDown() {
    this.modelDropDownToggle = !this.modelDropDownToggle;
    this.dropDownForm.controls['firstDropDown'].valueChanges.subscribe(value => {
      if (value !== this.dropDownForm.value['firstDropDown']) {
        this.thirdDropDownValue = [];
        this.secondDropDownValue = [];
        this.dropDownForm.patchValue({
          secondDropDown: null,
          secondDropDownVal: null,
          thirdDropDown: null,
          thirdDropDownVal: null,
        });
      }
    });
  }

  openNaviDropDown() {
    this.naviDropDownToggle = !this.naviDropDownToggle;
  }

  closeDropDown() {
    this.brandDropDownToggle = false;
    this.modelDropDownToggle = false;
    this.naviDropDownToggle = false;
  }

  fetchCarModelData(id: number | null, name: string | null) {
    this.dropDownForm.patchValue({
      firstDropDown: id,
      firstDropDownVal: name
    });
    if (this.dropDownForm.value.firstDropDown) {
      this.secondDropDownValue = this.dataService.getOtherDropDownValues(Number(this.dropDownForm.value.firstDropDown));
    }
    this.brandDropDownToggle = false;
  }

  fetchCarNaviData(id: number | null, name: string | null) {
    this.dropDownForm.patchValue({
      secondDropDown: id,
      secondDropDownVal: name
    });
    if (this.dropDownForm.value.secondDropDown) {
      this.thirdDropDownValue = this.dataService.getOtherDropDownValues(Number(this.dropDownForm.value.secondDropDown));
    }
    this.modelDropDownToggle = false;
  }

  setNaviDropDownValue(id: number | null, name: string | null) {
    this.dropDownForm.patchValue({
      thirdDropDown: id,
      thirdDropDownVal: name
    });
    this.naviDropDownToggle = false;
  }

  fetchPackageData() {
    this.submitted = true;
    this.package = [];
    if (this.dropDownForm.invalid) {
      return;
    }
    // this.downloadUrlData = [];
    const packageId: {
      PACKAGE_ID: number,
      VEHICLE_MODEL_ID: number
    }[] = this.dataService.getPackageId(this.getPackageDataDropDownValue());
    let result: any = [];
    for (const val of packageId) {
      result.push(this.dataService.getPackageData(val.PACKAGE_ID));
    }
    this.package = result;
    this.submitted = false;
  }

  getPackageDataDropDownValue() {
    const oemName = this.dataService.getOemName();
    if (oemName === 'stellantis') {
      return this.dropDownForm.value.thirdDropDown
    } else if (oemName === 'iveco') {
      return this.dropDownForm.value.firstDropDown
    } else {
      return this.dropDownForm.value.secondDropDown
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.scrollDown = window.scrollY;
  }

  // trackSelection(brand: string, model: string, navisystem: string) {
  //   this.monitoring.logEvent(
  //     'CDN Resouce loaded',
  //     {
  //       url: 'https://cdn1endpoint-dev-cdp-fca-test.azureedge.net',
  //       Brand: brand,
  //       Model: model,
  //       Navisystem: navisystem,
  //     }
  //   );
  // }
  // ;trackSelection(dropDownForm.value.firstDropDownVal,dropDownForm.value.secondDropDownVal,dropDownForm.value.thirdDropDownVal)
}
