<div class="tt-wrapper">
    <div class="tt-container tt-banner-module">
        <div id="bannerCarousel" class="carousel slide" data-ride="carousel">
            <ol class="carousel-indicators hidden-md hidden-lg">
                <li data-target="#bannerCarousel" data-slide-to="0" class="active"></li>
            </ol>
            <div class="container tt-container tt-dashboard-module">
                <div class="errorClass">
                    <span>
                        {{'index.error_msg1'| translate}}<br />
                        {{'index.error_msg2'| translate}}<br />
                        {{'index.error_msg3'| translate}}
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>