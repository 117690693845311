<div class="moreInfoDiv">
    <div *ngIf="subscribedData1=='download'" id="downloadMoreInfo" class="downloadMoreInfo">
        <div class="helpSpanClass">
            <h3 class="color-style" id="helpDownload1" [innerHtml]="'help.download' | translate">
            </h3>
            <ul class="font-styling">
                <li [innerHtml]="'help.download1' | translate"></li>
                <li [innerHtml]="'help.download2' | translate"></li>
                <li>
                    <div [innerHtml]="'help.download3' | translate"></div>
                </li>
            </ul>
        </div>
    </div>

    <div *ngIf="subscribedData1=='transfer'" id="transferMoreInfo" class="transferMoreInfo">
        <div class="helpSpanClass">
            <h3 id="helpTransfer21" class="color-style" [innerHtml]="'help.transfer' | translate">
            </h3>
            <ul class="font-styling">
                <li [innerHtml]="'help.transfer1' | translate"></li>
                <li [innerHtml]="'help.transfer2' | translate"></li>
                <li [innerHtml]="'help.transfer3' | translate"></li>
            </ul>
        </div>
    </div>

    <div *ngIf="subscribedData1=='install'" id="installMoreInfo" class="installMoreInfo">
        <div class="helpSpanClass">
            <h3 id="helpInstall5" class="color-style" [innerHtml]="'help.install' | translate">
            </h3>
            <ul class="font-styling">
                <li [innerHtml]="'help.install1' | translate"></li>
                <li [innerHtml]="'help.install2' | translate"></li>
                <li>
                    <div [innerHtml]="'help.install3' | translate">
                    </div>
                </li>
                <li [innerHtml]="'help.install4' | translate"></li>
                <li [innerHtml]="'help.install5' | translate"></li>
            </ul>
        </div>
    </div>
    <div class="height-style"></div>
</div>