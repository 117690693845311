import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { ErrorComponent } from './components/error/error.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { FcaDropDownComponent } from './components/drop-down/fca-drop-down/fca-drop-down.component';
import { OpelDropDownComponent } from './components/drop-down/opel-drop-down/opel-drop-down.component';
import { IvecoDropDownComponent } from './components/drop-down/iveco-drop-down/iveco-drop-down.component';
import { MgnDropDownComponent } from './components/drop-down/mgn-drop-down/mgn-drop-down.component';
import { SsangyongDropDownComponent } from './components/drop-down/ssangyong-drop-down/ssangyong-drop-down.component';
import { MelcoDropDownComponent } from './components/drop-down/melco-drop-down/melco-drop-down.component';
import { CdpRoutingModule } from '../cdp/cdp.routing.module';
import { CookieService } from 'ngx-cookie-service';
import { FcaHelpSectionComponent } from './components/help-section/fca-help-section/fca-help-section.component';
import { IvecoHelpSectionComponent } from './components/help-section/iveco-help-section/iveco-help-section.component';
import { FcaDownloadPopupComponent } from './components/download-popup/fca-download-popup/fca-download-popup.component';
import { IvecoDownloadPopupComponent } from './components/download-popup/iveco-download-popup/iveco-download-popup.component';
import { FcaMyDownloadsComponent } from './components/my-downloads/fca-my-downloads/fca-my-downloads.component';
import { IvecoMyDownloadsComponent } from './components/my-downloads/iveco-my-downloads/iveco-my-downloads.component';
import { OpelDownloadPopupComponent } from './components/download-popup/opel-download-popup/opel-download-popup.component';
import { SsangyongDownloadPopupComponent } from './components/download-popup/ssangyong-download-popup/ssangyong-download-popup.component';
import { MelcoDownloadPopupComponent } from './components/download-popup/melco-download-popup/melco-download-popup.component';
import { MgnDownloadPopupComponent } from './components/download-popup/mgn-download-popup/mgn-download-popup.component';
import { OpelMyDownloadsComponent } from './components/my-downloads/opel-my-downloads/opel-my-downloads.component';
import { MelcoMyDownloadsComponent } from './components/my-downloads/melco-my-downloads/melco-my-downloads.component';
import { MgnMyDownloadsComponent } from './components/my-downloads/mgn-my-downloads/mgn-my-downloads.component';
import { SsangyongMyDownloadsComponent } from './components/my-downloads/ssangyong-my-downloads/ssangyong-my-downloads.component';
import { OpelHelpSectionComponent } from './components/help-section/opel-help-section/opel-help-section.component';
import { SsangyongHelpSectionComponent } from './components/help-section/ssangyong-help-section/ssangyong-help-section.component';
import { MelcoHelpSectionComponent } from './components/help-section/melco-help-section/melco-help-section.component';
import { MgnHelpSectionComponent } from './components/help-section/mgn-help-section/mgn-help-section.component';
import { SortPipe } from './pipes/sortingPipe';


@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    ErrorComponent,
    StepperComponent,
    FcaDropDownComponent,
    OpelDropDownComponent,
    IvecoDropDownComponent,
    MgnDropDownComponent,
    SsangyongDropDownComponent,
    MelcoDropDownComponent,
    FcaHelpSectionComponent,
    IvecoHelpSectionComponent,
    FcaDownloadPopupComponent,
    IvecoDownloadPopupComponent,
    FcaMyDownloadsComponent,
    IvecoMyDownloadsComponent,
    OpelDownloadPopupComponent,
    SsangyongDownloadPopupComponent,
    MelcoDownloadPopupComponent,
    MgnDownloadPopupComponent,
    OpelMyDownloadsComponent,
    MelcoMyDownloadsComponent,
    MgnMyDownloadsComponent,
    SsangyongMyDownloadsComponent,
    OpelHelpSectionComponent,
    SsangyongHelpSectionComponent,
    MelcoHelpSectionComponent,
    MgnHelpSectionComponent,
    SortPipe
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    CdpRoutingModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    ErrorComponent,
    StepperComponent,
    FcaDropDownComponent,
    OpelDropDownComponent,
    IvecoDropDownComponent,
    MgnDropDownComponent,
    SsangyongDropDownComponent,
    SsangyongMyDownloadsComponent,
    SsangyongDownloadPopupComponent,
    MelcoDropDownComponent,
    FcaHelpSectionComponent,
    IvecoHelpSectionComponent,
    SsangyongHelpSectionComponent,
    OpelHelpSectionComponent,
    MgnHelpSectionComponent,
    TranslateModule,
    MelcoHelpSectionComponent,
    SortPipe
  ],
  providers: [CookieService]
})
export class SharedModule { }
