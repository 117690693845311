import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { JSONPath } from 'jsonpath-plus';
import oemData from '../../assets/data/oem.json';
import vechileModelTypeData from '../../assets/data/vehicle_model_type.json';
import vechileModelData from '../../assets/data/vehicle_model.json';
import targetJson from '../../assets/data/target.json';
import packageJson from '../../assets/data/package.json';
import urlJson from '../../assets/data/urlmap.json';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  oemName: string = environment.oem;
  localeCodeCookie: BehaviorSubject<string> = new BehaviorSubject('');
  isErrorPageLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  constructor(
    private http: HttpClient
  ) { }

  getOemNameFromRoute(url: string) {
    let urlArr: string[];
    urlArr = url.split('/');
    return urlArr[1];
  }

  setOemName(oem: string) {
    this.oemName = oem;
  }

  getOemName() {
    return environment.oem;
  }

  getLocales(oemName: string) {
    return this.http.get(`/assets/i18n/${oemName}/locale.json`);
  }


  // fetch data from JSON
  getOemId(oemName: string) {
    const query = `$..[?(@.NAME=='${oemName}')]`;
    const result = JSONPath({ path: query, json: oemData });
    return result[0];
  }

  getVehicleInfo(oemId: number) {
    const query = `$..[?(@.OEM_ID==${oemId})]`;
    const result = JSONPath({ path: query, json: vechileModelTypeData });
    return result;
  }

  getFirstDropDownValue(id: number, parentId: number | null = 0) {
    const query = `$..[?(@.VEHICLE_MODEL_TYPE_ID==${id} && @.PARENT_ID==${parentId})]`;
    const result = JSONPath({ path: query, json: vechileModelData });
    return result ? result : 'error';
  }

  getOtherDropDownValues(parentId: number) {
    const query = `$..[?(@.PARENT_ID==${parentId})]`;
    const result = JSONPath({ path: query, json: vechileModelData });
    return result ? result : 'error';
  }

  getPackageId(targetId: number) {
    const query = `$..[?(@.VEHICLE_MODEL_ID==${targetId})]`;
    const result = JSONPath({ path: query, json: targetJson });
    return result ? result : 'error';
  }

  getPackageData(packageId: number) {
    const query = `$..[?(@.ID==${packageId})]`;
    let result = JSONPath({ path: query, json: packageJson });
    return result ? result : 'error';
  }

  getUrlMapData(packageId: number) {
    const query = `$..[?(@.PACKAGE_ID==${packageId})]`;
    let result = JSONPath({ path: query, json: urlJson });
    return result ? result : 'error';
  }
}
